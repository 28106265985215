@use '/src/styles/base.scss' as *;

.SeverityTag {
    display: grid;
    width: 1.3125rem;
    height: 1.3125rem;
    padding: 0.25rem;
    place-items: center;
    background-color: $gray-3;
    border-radius: 0.125rem;

    font-family: $font-family_primary;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    color: $white;

    &--critical {
        background-color: $volcano;
    }
    &--high {
        background-color: $amber;
    }
    &--medium {
        background-color: $yellow;
    }
    &--low {
        background-color: $cyan;
    }

    &--clickable {
        cursor: pointer;
    }
}
