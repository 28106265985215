@import 'src/styles/base.scss';

.report-image-form {
    @include BODY_NORMAL();

    &__label {
        font-weight: 600;
        color: $gray-9;
        font-size: $font-size_title_tiny;
        
    }
    &__help {
        color: $gray-8;
        font-size: $font-size_body_tiny;
    }
    &__uploadZone {
        height: 77px !important;
    }
    &__container {
        padding: 32px 0;
    }
}