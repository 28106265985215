@import 'src/styles/base.scss';

.page-template-manage {
    &__body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        height: 100%;
    }

    &__switch {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem 1rem 0 1rem;
        margin-bottom: -1rem;
    }

    &__choose {
        @include BODY_NORMAL(500);
    }

    &__form {
        padding-top: 1rem;
        overflow: auto;
    }

    &__selector {
        &__unstyled-list {
            & > li {
                padding: 0 0.5rem;
                height: 2.3rem;
            }
        }
        &__category {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
        }
        &__item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 1.5rem;
            gap: 0.5rem;
        }
    }
}
