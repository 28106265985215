.th-user-selector {
    .ant-select-selector {
        min-height: 33px;
        height: auto !important;
    }

    .th-user-selector-tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 3px;

        .iconlabel_icon {
            display: flex;
            flex-direction: column;
            align-items: center;

            .user-avatar {
                height: 18px;
                width: 18px;
                line-height: 16px;

                .ant-avatar-string {
                    font-size: 0.9em;
                }
            }
        }
    }

    .ant-select-selector .ant-select-selection-item .icon-label {
        white-space: break-spaces;
        line-height: 1.5em;
    }
}
