.report-template-helper__menu-btn {
    padding: .25rem 0 !important;

    &-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: .25rem;
    }

}