@import 'src/styles/base.scss';

.templates__layout-content {
    height: 100%;

    display: flex;
    flex-direction: column;

    .th-tabs {
        .ant-tabs-nav {
            margin: 0;
            margin-top: 0.7rem;
        }
    }
}
